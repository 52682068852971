.subscribe{
  position: fixed; left: 0px; right: 0px; top: 0px; bottom: 0px; overflow: hidden; margin: 0; padding: 0;
}
.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url("websitebg.jpg"); */
    /* background-color: black; */
  }
  
  .logo {
    /* width: 600px;
    height: 50px; */
    position: absolute;
    margin-top: -220px;
    margin-left: 20px;
    margin-right: 20px;
    /* margin-left: -100px; */
  }
  
  .icons {
    position:absolute;
    /* margin-top: 200px; */
  }
  
  @media only screen and (min-width: 601px) {
    div.description {
      font-size: medium;
    }
  }
  
 
  
  .bgimage {
    position:absolute;
    margin-top:-200px;
    max-width:100%;
    max-height:100%;
    background-position: center;
    mix-blend-mode: screen;
    object-fit: contain;
    background-repeat: no-repeat;
    /* opacity: 50%; */
  }
  
  .description {
    position: absolute;
    padding-bottom: 40px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size:medium;
    font-style:normal;
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .thankyou {
    position: absolute;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size:medium;
    font-style:normal;
    color: white;
    text-align: center;
    margin-top: 0px;
    text-transform: uppercase;
  }
  
  .content {
    position:absolute;
    /* margin-top: 100px; */
    width: 360px;
    height: 40px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
    border-radius: 60px;
    overflow: hidden;
  }
  
  .subscription {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .subscription .add-email{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 20px;
    background: black;
    color:#FFFFFF;
  }
  
  .subscription .submit-email {
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 2px);
    width: 100px;
    border: none;
    border-radius: 60px;
    outline: none;
    margin: 1px;
    padding: 0 20px;
    cursor: pointer;
    background: #685575;
    color: #FFFFFF;
    transition: width .35s ease-in-out,
      background .35s ease-in-out;
  }
  
  .subscription.done .submit-email {
    width: calc(100% - 2px);
    background: #7d7384;
  }
  
  .subscription .submit-email .before-submit,
  .subscription .submit-email .after-submit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 38px;
    transition: visibility .35s ease-in-out,
      opacity .35s ease-in-out;
  }
  
  .subscription.done .submit-email .before-submit,
  .subscription:not(.done) .submit-email .after-submit {
    visibility: hidden;
    opacity: 0;
  }
  
  .subscription .submit-email .after-submit {
    transition-delay: .35s;
  }
  
  .subscription:not(.done) .submit-email .before-submit,
  .subscription.done .submit-email .after-submit {
    visibility: visible;
    opacity: 1;
  }

  .subscription-canvas{
    width:100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }