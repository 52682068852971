@media all and (max-width: 640px) {
    .lil-gui.root {
      right: auto;
      top: auto;
      max-height: 50%;
      max-width: 80%;
      bottom: 0;
      left: 0;
    }
  }

  .webgl {
    /* position: absolute; */
    top: 0;
    left: 0;
    outline: none;
    z-index: 0;
    touch-action: none;
  }