/* Importing custom fonts */

@font-face {
    font-family: 'Avenir';
    src: url(./fonts/Avenir.ttc) ;
}

@font-face {
    font-family: 'Disolve light';
    src: url(./fonts/Disolve_light.ttf) ;
}

@font-face {
    font-family: 'Disolve regular';
    src: url(./fonts/Disolve_regular.ttf) ;
}

.screen-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}