.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background-color: black;
  }

  .metadata {
    z-index: 100;
    color: aliceblue;
    position: absolute;
  }